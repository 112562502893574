import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, DeviceIcon } from '@crazyegginc/hatch';

import { AB_TEST_STATUSES } from '../constants';
import { AbTestActionButton } from './AbTestActionButton';
import { abTestDuration, abTestCreatedBy } from '../utils';
import { LinkWithParams } from '/src/components/LinkWithParams';
import { displayUrl } from '/src/utils/url';
import { abbreviateNumber } from '/src/utils';
import { ListCard, ListCardMetric } from '/src/features/ab-testing/components/ListCard';
import { PreviewLink } from '/src/features/ab-testing/components/PreviewLink';

import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

export function AbTestListCard({ abTest }) {
  const goalDisplayData = useMemo(() => {
    if (!abTest?.goal?.displayData) return null;
    return JSON.parse(abTest.goal.displayData);
  }, [abTest?.goal?.displayData]);

  const testDurationField = abTestDuration(abTest);
  const testCreatedByField = abTestCreatedBy(abTest);

  const isDraft = abTest.status === AB_TEST_STATUSES.DRAFT;

  return (
    <ListCard
      abTest={abTest}
      key={abTest.id}
      counters={
        <>
          {!isDraft ? (
            <ListCardMetric
              value={abbreviateNumber(abTest.totalVisitors)}
              title={`${abTest.totalVisitors} visitors`}
              unit="visitors"
              className="px-1"
            />
          ) : null}
          <ListCardMetric
            value={abTest.variantsCount}
            tooltipContent={
              <div className="flex flex-col items-start space-y-2.5 p-2 text-xs font-normal leading-4">
                {abTest.variants.map((variant) => {
                  return (
                    <PreviewLink variant={variant} key={`${abTest.id}_${variant.id}_previewLink`}>
                      <div className="flex items-center space-x-1.5 hover:underline">
                        <span>View {variant.variantName}</span>
                        <ExpandIcon className="h-2.5 w-2.5 rotate-90 fill-current" aria-label="follow link" />
                      </div>
                    </PreviewLink>
                  );
                })}
              </div>
            }
            interactive={true}
            unit="variants"
            className="border-b border-dashed px-1"
          />
        </>
      }
      actionButton={<AbTestActionButton entity={abTest} />}
      primaryButton={
        isDraft ? (
          <Button
            leftIcon={<EditIcon className="mr-1.5 h-4 w-4 fill-current text-dodger-blue-500" />}
            component={LinkWithParams}
            to={`/ab-tests/${abTest.id}/edit`}
            variant="secondary"
            className="shrink-0"
          >
            Edit Draft
          </Button>
        ) : (
          <Button component={LinkWithParams} to={`/ab-tests/${abTest.id}`} variant="secondary" className="shrink-0">
            View Results
          </Button>
        )
      }
    >
      <div className="flex min-w-0 flex-col space-y-2">
        <div className="mb-0.5 flex min-w-0 space-x-5">
          <div className="text-body-5 w-14 shrink-0">Page</div>
          <div className="text-body-2 truncate">{displayUrl(abTest.matchingUrl)}</div>
        </div>

        <div className="mb-0.5 flex space-x-5">
          <div className="text-body-5 w-14">Goal</div>
          {abTest?.goal ? (
            <div className="text-body-2 text-link">
              <Link to={`/goals/${abTest.goal.id}?back=/ab-tests`}>
                {goalDisplayData.name || goalDisplayData.auto_name}
              </Link>
            </div>
          ) : (
            <div className="text-body-2">-</div>
          )}
        </div>

        <div className="mb-0.5 flex items-start">
          <div className="flex w-60 space-x-5">
            <div className="text-body-5 w-14">Device</div>
            <div className="flex items-center space-x-3">
              {abTest.devices.map((d) => (
                <DeviceIcon device={d} key={d} />
              ))}
            </div>
          </div>
          <div className="flex space-x-5">
            <div className="text-body-5 w-14 capitalize">{testDurationField.label}</div>
            <div className="text-body-2">
              {testDurationField.value} {testDurationField?.description ? `(${testDurationField.description})` : null}
            </div>
          </div>
        </div>

        <div className="mb-0.5 flex items-start">
          <div className="flex w-60 space-x-5">
            <div className="text-body-5 w-14 capitalize">ID</div>
            <div className="text-body-2">{abTest.id}</div>
          </div>

          <div className="flex space-x-5">
            <div className="text-body-5 w-14 capitalize">{testCreatedByField.label}</div>
            <div className="text-body-2">
              {testCreatedByField.value}
              {testCreatedByField.author && <div className="text-caption">by {testCreatedByField.author}</div>}
            </div>
          </div>
        </div>
      </div>
    </ListCard>
  );
}
