import classNames from 'classnames';
import { Checkbox } from '@crazyegginc/hatch';

const dynamicFilterIcons = import.meta.globEager(`./dynamic-filter-icons/*.png`);

export function FilterCheckBoxes({ options, value, onChange, error }) {
  const handleChange = (valueToChange) => {
    let newValue = [...value];
    if (value.includes(valueToChange)) {
      newValue = newValue.filter((i) => i !== valueToChange);
    } else {
      newValue = [...newValue, valueToChange];
    }
    onChange(newValue);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap">
        {options.map((option) => (
          <FilterCheckBox
            key={option.value}
            label={option.display_name}
            checked={value.includes(option.value)}
            icon={option.display_icon}
            onChange={() => handleChange(option.value)}
            error={error}
          />
        ))}
      </div>
      {error && (
        <div className="relative h-5">
          <div className="text-body-4 absolute -translate-y-1 whitespace-nowrap text-carnation-500">{error}</div>
        </div>
      )}
    </div>
  );
}

function FilterCheckBox({ label, icon, checked, onChange, error }) {
  return (
    <div
      className={classNames('m-1.25 ml-0 flex h-[35px] items-center rounded border px-2.5', {
        'border-mystic-500': !error && !checked,
        'border-dodger-blue-500': !error && checked,
        'border-carnation-500': error,
      })}
    >
      <Checkbox
        onChange={onChange}
        checked={checked}
        id={label}
        label={
          <div className="flex items-center justify-center">
            {icon && (
              <img
                src={dynamicFilterIcons[`./dynamic-filter-icons/${icon}.png`]?.default}
                alt={label}
                style={{ paddingRight: '8px' }}
              />
            )}
            {label}
          </div>
        }
      />
    </div>
  );
}
