import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

import { GoalTriggerSummary } from '/src/features/goals/utils';

export function GoalTriggersModal({ goal, goalDisplayData }) {
  const modal = useModal();
  const { isSharing } = useAuthContext();

  const panelLink = `/goals/${goal?.id}`;
  const panelTitle = goalDisplayData?.name || goalDisplayData?.auto_name;

  return (
    <Modal dialogClassName="">
      <Modal.Title>
        <div className="text-header-2">
          Goal:&nbsp;
          {!isSharing ? (
            <Link to={panelLink} className="text-dodger-blue-500 hover:underline">
              {panelTitle}
            </Link>
          ) : (
            panelTitle
          )}
        </div>
      </Modal.Title>
      <GoalTriggerSummary goal={goal} showMaxTriggers={null} />
      <Modal.Actions>
        <Button
          onClick={() => {
            modal.close();
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
