import {
  format,
  formatDistanceToNow,
  fromUnixTime,
  differenceInCalendarDays,
  addMonths,
  startOfMonth,
  startOfDay,
  endOfDay,
  addDays,
  getUnixTime,
  endOfMonth,
  parseISO,
} from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, getTimezoneOffset } from 'date-fns-tz';

import { clamp } from '/src/utils/math';
import { SPECIAL_DATE_RANGES } from '/src/features/_global/constants';

const { TODAY, LAST_7_DAYS, LAST_30_DAYS, LAST_MONTH, THIS_MONTH } = SPECIAL_DATE_RANGES;

export function formatDate(input) {
  return format(fromUnixTime(input), `MMM dd, yyyy`).replace(/\./g, '');
}

export function formatLongDate(input) {
  return format(fromUnixTime(input), `EEEE do MMMM, yyyy`).replace(/\./g, '');
}

export function formatDateTime(input) {
  return format(fromUnixTime(input), `MMM dd, yyyy 'at' h:mmaaaa`).replace(/\./g, '');
}

export function formatShortDateTime(input) {
  return format(fromUnixTime(input), `MMM dd, h:mm aaaa`).replace(/\./g, '');
}

export function formatFullDateTime(input) {
  return format(fromUnixTime(input), `MMMM dd, y, h:mm aaaa`).replace(/\./g, '');
}

export function utcStringToLocalTimeObject(input) {
  return utcToZonedTime(parseISO(input));
}

export function utcStringToLocalTimeFullDateTime(input) {
  return format(utcToZonedTime(parseISO(input)), `MMMM dd, y, h:mm aaaa`).replace(/\./g, '');
}

export function utcStringToLocalTimeDate(input) {
  return format(utcToZonedTime(parseISO(input)), `MMM dd, y`).replace(/\./g, '');
}

export function formatTime(input) {
  return format(fromUnixTime(input), `h:mm aaa`).replace(/\./g, '');
}

function getDateInServerLocalTime(input) {
  const serverTimeZone = 'Etc/UTC';
  const utcTime = zonedTimeToUtc(
    format(input, 'yyyy-MM-dd HH:mm'),
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
  );
  return utcToZonedTime(utcTime, serverTimeZone);
}

export function formattedCurrentDate() {
  return new Date()
    .toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .replaceAll('/', '-');
}

export function getDaysRemainingInMonthServerTime() {
  const serverTime = getDateInServerLocalTime(new Date());
  return clamp(
    differenceInCalendarDays(getDateInServerLocalTime(addMonths(startOfMonth(serverTime), 1)), serverTime),
    0,
    31,
  );
}

function padNumber(value, padded = false) {
  if (!padded) return value;

  if (value >= 10) {
    return value;
  }
  return `0${value}`;
}

export function msToHHMMSS(ms, padded = false) {
  let seconds = Math.floor(ms / 1000);
  const hours = parseInt(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = parseInt(seconds / 60);
  seconds = seconds % 60;

  if (hours > 0) {
    return `${padNumber(hours, padded)}:${padNumber(minutes, true)}:${padNumber(seconds, true)}`;
  }
  return `${padNumber(minutes, padded)}:${padNumber(seconds, true)}`;
}

export function msToHumanDuration(ms) {
  let seconds = Math.floor(ms / 1000);
  const hours = parseInt(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = parseInt(seconds / 60);
  seconds = seconds % 60;

  let returnValue = '';
  if (hours > 0) {
    returnValue += ` ${hours}h`;
  }
  if (minutes > 0) {
    returnValue += ` ${minutes}m`;
  }
  returnValue += ` ${seconds}s`;

  return returnValue.trim();
}

export function formatDuration(input, { units = 'ms', padded = false } = {}) {
  if (!['ms', 's'].includes(units)) {
    throw new Error('formatDuration only accepts seconds (s) and milliseconds (ms)');
  }
  const date = new Date(units === 'ms' ? input : input * 1000);
  return format(date, padded ? 'mm:ss' : 'm:ss');
}

const calcZonedDate = (date, fn, tz = 'Etc/UTC') => {
  const inputZoned = utcToZonedTime(date, tz);
  const fnZoned = fn(inputZoned);
  return zonedTimeToUtc(fnZoned, tz);
};

const getZonedEndOfDay = (date, timeZone) => {
  return calcZonedDate(date, endOfDay, timeZone);
};
const getZonedStartOfDay = (date, timeZone) => {
  return calcZonedDate(date, startOfDay, timeZone);
};
const getZonedEndOfMonth = (date, timeZone) => {
  return calcZonedDate(date, endOfMonth, timeZone);
};
const getZonedStartOfMonth = (date, timeZone) => {
  return calcZonedDate(date, startOfMonth, timeZone);
};

export function getConvertedDateRange(daterange, toUTC = false) {
  let startDate, endDate;

  const now = new Date();
  switch (daterange.special) {
    case TODAY:
      startDate = toUTC ? getZonedStartOfDay(now) : startOfDay(now);
      endDate = toUTC ? getZonedEndOfDay(now) : endOfDay(now);
      break;
    case LAST_7_DAYS:
      startDate = toUTC ? getZonedStartOfDay(addDays(now, -7)) : startOfDay(addDays(now, -7));
      endDate = toUTC ? getZonedEndOfDay(now) : endOfDay(now);
      break;
    case LAST_30_DAYS:
      startDate = toUTC ? getZonedStartOfDay(addDays(now, -30)) : startOfDay(addDays(now, -30));
      endDate = toUTC ? getZonedEndOfDay(now) : endOfDay(now);
      break;
    case THIS_MONTH:
      startDate = toUTC ? getZonedStartOfMonth(now) : startOfMonth(now);
      endDate = toUTC ? getZonedEndOfMonth(now) : endOfMonth(now);
      break;
    case LAST_MONTH:
      startDate = toUTC ? getZonedStartOfMonth(addMonths(now, -1)) : startOfMonth(addMonths(now, -1));
      endDate = toUTC ? getZonedEndOfMonth(addMonths(now, -1)) : endOfMonth(addMonths(now, -1));
      break;
    default:
      return {
        startDate: getUnixTime(daterange.start_date) + (toUTC ? getTimeZoneOffset() : 0),
        endDate: getUnixTime(daterange.end_date) + (toUTC ? getTimeZoneOffset() : 0),
      };
  }
  return {
    startDate: getUnixTime(startDate),
    endDate: getUnixTime(endDate),
  };
}

export function getInitialDate(queryParam) {
  if (queryParam) {
    let param;
    try {
      param = JSON.parse(queryParam);
    } catch {
      return null;
    }
    if (param.special) {
      if (![TODAY, LAST_7_DAYS, LAST_30_DAYS, THIS_MONTH, LAST_MONTH].includes(param.special)) {
        return null;
      }
      return param;
    } else {
      if (Number.isInteger(param.start_date) && Number.isInteger(param.end_date)) {
        if (param.start_date > param.end_date) {
          return null;
        }
      } else {
        return null;
      }
      return { start_date: fromUnixTime(param.start_date), end_date: fromUnixTime(param.end_date) };
    }
  }
  return null;
}

export function dateDistance(date) {
  return formatDistanceToNow(fromUnixTime(date), { includeSeconds: true, addSuffix: true });
}

export function getTimeZoneOffset() {
  return getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) / 1000;
}
