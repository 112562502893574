import { haveHTTPSRegex } from '/src/utils/regex';
import { hasWildcard, displayUrl } from '/src/utils/url';
import { DAYS_IN_MONTH } from '/src/features/options/constants';

// VALIDATION
export function noDuplicationInValidation(val, type) {
  const valueArr = val.map((v) => (v[type] === undefined ? v[type] : displayUrl(v[type]))).filter(Boolean);
  return new Set(valueArr).size !== valueArr.length;
}

function getAndSanitizeArrayofUrls(urls) {
  const arrofObj = urls.rules;
  arrofObj.map((arr) => {
    Object.keys(arr).forEach((key) => {
      if (key === 'u') {
        arr[key] = arr[key].replace(/^\*:\/\//, '');
      }
    });
  });

  return arrofObj;
}

export function getInitialValues(type, value) {
  if (type === 'bool') {
    return value ?? false;
  } else {
    if (value) {
      const parsevalue = JSON.parse(value);
      return type === 'css' ? parsevalue : getAndSanitizeArrayofUrls(parsevalue);
    } else {
      return [];
    }
  }
}

// LIST OF INPUTS
export function checkLastInputEmpty(listData, typeCSS) {
  if (listData) {
    if (listData.length > 0) {
      return listData.at(-1)?.[typeCSS ? 'selector' : 'u'] === '';
    }
  }
  return false;
}

export function haveErrors({ isTypeCSS, touched, errors, data, index }) {
  if (touched?.[data]?.[index]) {
    return isTypeCSS ? errors?.[data]?.[index]?.selector : errors?.[data]?.[index]?.u;
  }
}

const arrOfURLs = (target) => {
  const url = target;
  const haveProtocol = haveHTTPSRegex.test(url);

  if (hasWildcard(url) || !haveProtocol) {
    return {
      u: url,
      o: '*',
    };
  } else {
    return {
      u: url,
    };
  }
};

export function returnURLObjectValue({ isTypeCSS, setFieldValue, data, index, value }) {
  if (!isTypeCSS) {
    const updateValue = arrOfURLs(value);
    setFieldValue(`${data}[${index}]`, updateValue);
  } else {
    setFieldValue(`${data}[${index}].selector`, value);
  }
}

// SUBMISSION
export function returnArrayOfURLsForSubmission(arrURLs) {
  const newArr = arrURLs.filter((arr) => arr.u !== '');

  newArr.map((arr) => {
    for (let item of Object.keys(arr)) {
      if (item === 'o') {
        arr.u = arr.u.match(/^\*:\/\//) || arr.u.match(/^https?:\/\//) ? arr.u : `*://${arr.u}`;
      }
    }
  });

  return newArr;
}

/*
number of days per month = always 30.4 (average)
const DAYS_IN_MONTH = 30.4

CALCULATION:
  Automatic: 
    1/[siteRatio] * 100 
    ie. 1/11 * 100 = 9.09%

  Manual:
    number: 
    ([user_input] /100) * [estimated_visits_day] * DAYS_IN_MONTH
    Ie. (100/100) * 2567 * 31 = 79,577 recordings

    percentage:
    ([user_input] / DAYS_IN_MONTH / [estimated_visits_day] ) * 100
    Ie. (39788 / 2567 / 31) * 100 = 49.99% sampling rate
*/

export function calculateRecordingRatio(ratio) {
  const calculateRatio = ((1 / ratio) * 100).toFixed(2);
  return parseFloat(calculateRatio);
}

export function numberToPercentage(value, visitsDay) {
  const calculateNumber = ((value / DAYS_IN_MONTH / visitsDay) * 100).toFixed(2);
  return parseFloat(calculateNumber);
}

export function percentageToNumber(value, visitsDay) {
  return (value / 100) * visitsDay * DAYS_IN_MONTH;
}
