export const SAMPLING_TYPE = {
  AUTO: 'AUTO',
  MANUAL: 'MANUAL',
};

export const NUMBER_TYPE = {
  RATE: 'RATE',
  STATIC: 'STATIC',
};

export const DAYS_IN_MONTH = 30.4;
