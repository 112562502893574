import ClickWebP from '@crazyegginc/hatch/dist/images/illustration-button.webp';
import ClickPNG from '@crazyegginc/hatch/dist/images/illustration-button.png';
import UrlVisitWebP from '@crazyegginc/hatch/dist/images/illustration-webpage-tick.webp';
import UrlVisitPNG from '@crazyegginc/hatch/dist/images/illustration-webpage-tick.png';
import FormSubmissionWebP from '@crazyegginc/hatch/dist/images/illustration-form.webp';
import FormSubmissionPNG from '@crazyegginc/hatch/dist/images/illustration-form.png';
import CodeWebP from '@crazyegginc/hatch/dist/images/illustration-javascript-page.webp';
import CodePNG from '@crazyegginc/hatch/dist/images/illustration-javascript-page.png';
import GoalWebP from '@crazyegginc/hatch/dist/images/illustration-goal-target.webp';
import GoalPNG from '@crazyegginc/hatch/dist/images/illustration-goal-target.png';
import PixelWebP from '@crazyegginc/hatch/dist/images/illustration-pixel.webp';
import PixelPNG from '@crazyegginc/hatch/dist/images/illustration-pixel.png';

export const GOAL_TRIGGER_TYPES = {
  CLICK: 'CLICK',
  URL_VISIT: 'URL_VISIT',
  FORM_SUBMISSION: 'FORM_SUBMISSION',
  PIXEL_EVENT: 'PIXEL_EVENT',
  CODE: 'CODE',
};

export const GOAL_TRIGGERS_METADATA = {
  [GOAL_TRIGGER_TYPES.CLICK]: {
    webp: ClickWebP,
    png: ClickPNG,
    description: (
      <span>
        <strong>Clicks an element</strong> on
      </span>
    ),
    label: (
      <span className="text-xs font-normal">
        When someone <strong className="font-semibold">clicks on a button or link</strong>
      </span>
    ),
    selectLabel: 'Clicks on a button or link',
  },
  [GOAL_TRIGGER_TYPES.URL_VISIT]: {
    webp: UrlVisitWebP,
    png: UrlVisitPNG,
    description: <strong>Visits</strong>,
    label: (
      <span className="text-xs font-normal">
        When someone <strong className="font-semibold">reaches a certain page</strong>
      </span>
    ),
    selectLabel: 'Reaches a certain page',
  },
  [GOAL_TRIGGER_TYPES.FORM_SUBMISSION]: {
    webp: FormSubmissionWebP,
    png: FormSubmissionPNG,
    description: (
      <span>
        <strong>Submits a form</strong> on
      </span>
    ),
    label: (
      <span className="text-xs font-normal">
        When someone
        <br />
        <strong className="font-semibold">fills out a form</strong>
      </span>
    ),
    selectLabel: 'Fills out a form',
  },
  [GOAL_TRIGGER_TYPES.PIXEL_EVENT]: {
    webp: PixelWebP,
    png: PixelPNG,
    description: (
      <span>
        <strong>Triggers a pixel conversion</strong> on
      </span>
    ),
    label: (
      <span className="text-xs font-normal">
        When someone triggers an ad
        <br />
        <strong className="font-semibold">conversion pixel</strong>
      </span>
    ),
    selectLabel: 'Triggers an ad conversion pixel',
  },
  [GOAL_TRIGGER_TYPES.CODE]: {
    webp: CodeWebP,
    png: CodePNG,
    description: (
      <span>
        <strong>Triggers a script</strong> on
      </span>
    ),
    label: (
      <span className="text-xs font-normal">
        When someone <strong className="font-semibold">triggers a script</strong>
        <br />
        <span className="text-lynch-500">(advanced)</span>
      </span>
    ),
    selectLabel: 'Triggers a script',
  },
  OTHER: {
    webp: GoalWebP,
    png: GoalPNG,
  },
};

export const PIXEL_API_LIST = {
  TIKTOK: 'tiktok',
  META: 'meta',
  GOOGLE: 'google',
};

export const ANY_PIXEL_ID = 'ANY_PIXEL_ID';
export const ENTER_YOUR_OWN_EVENT = 'Enter your own...';

export const GOOGLE_PIXEL_EVENTS = [
  { value: 'conversion', label: 'Conversion' },
  { value: 'ad_click', label: 'Ad Click' },
  { value: 'page_view', label: 'Page View' },
  { value: 'video_progress', label: 'Video Progress' },
  { value: 'scroll', label: 'Scroll' },
  { value: 'click', label: 'Click' },
  { value: 'file_download', label: 'File Download' },
];

export const GOAL_PURPOSES = {
  MORE_PAGE_VIEWS: 'MORE_PAGE_VIEWS',
  MORE_REGISTRATIONS: 'MORE_REGISTRATIONS',
  SELL_MORE: 'SELL_MORE',
  OTHER: 'OTHER',
};

export const ELEMENT_SELECTOR_TYPES = {
  SPECIFIC: 'SPECIFIC',
  BROAD: 'BROAD',
  MANUAL: 'MANUAL',
};

export const GOAL_TRACKING_OPTIONS = {
  SPECIFIC_PAGE: 'SPECIFIC_PAGE',
  ANY_PAGE: 'ANY_PAGE',
  WILDCARD: 'WILDCARD',
};

export const GOAL_UI_MODES = {
  SIMPLE: 'SIMPLE',
  ADVANCED: 'ADVANCED',
};

export const GOALS_DASHBOARD_PATH = '/goals';

export const GOAL_VALUE_TYPES = {
  FIXED: 'FIXED',
  ELEMENT: 'ELEMENT',
  CODE: 'CODE',
};
