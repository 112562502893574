import { useModal } from '/src/hooks';
import { ABTEST } from '/src/features/ab-testing/constants';
import { PreviewLoadingModal } from '/src/features/_global/modals/PreviewLoadingModal';
import { useCallback } from 'react';

export function PreviewLink({ variant, children }) {
  const modal = useModal();

  const launchPreview = useCallback(
    ({ viewVariantUrl }) => {
      window.open(viewVariantUrl, `_preview_variant_${viewVariantUrl}`);

      function handleMessage(e) {
        if (e.data.status === 'launched') {
          window.removeEventListener('message', handleMessage);
          modal.close();
        }
      }

      window.addEventListener('message', handleMessage);
    },
    [modal],
  );

  if (!variant.id || !variant.viewVariantUrl) {
    // we need the variant to be stored for the link to work
    return null;
  }

  return (
    <button
      onClick={() => {
        modal.show(<PreviewLoadingModal type={ABTEST} target="tab" spelledType="A/B Test" />);
        launchPreview(variant);
      }}
    >
      {children}
    </button>
  );
}
