import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Panel } from '@crazyegginc/hatch';

import { useModal, useAuthContext } from '/src/hooks';
import { WebP } from '/src/components/WebP';

import { GoalTriggerSummary } from '/src/features/goals/utils';
import { GoalTriggersModal } from './modals/GoalTriggersModal';
import { GOAL_TRIGGERS_METADATA } from '/src/features/goals/constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function AbTestGoalCard({ data }) {
  const { isSharing } = useAuthContext();
  const modal = useModal();

  const goal = data?.goal;
  const goalDisplayData = useMemo(() => {
    if (!goal?.displayData) return null;
    return JSON.parse(goal.displayData);
  }, [goal?.displayData]);

  if (!goal) return null;
  const panelLink = `/goals/${goal?.id}?back=${encodeURIComponent(`/ab-tests/${data?.id}`)}`;
  const panelTitle = goalDisplayData?.name || goalDisplayData?.auto_name;

  return (
    <section className="flex w-1/3 flex-col justify-stretch">
      <div className="text-header-3 mb-3.75">Goal</div>

      <Panel className="relative !grow !items-center !justify-around !p-[20px]">
        {(goalDisplayData?.triggers ?? []).length > 1 ? (
          <div className="absolute right-3.75 top-3.75">
            <Tooltip
              style={{ maxWidth: '230px' }}
              tooltipContent="If you have multiple goal triggers, we’ll count the first element triggered only as a conversion for that goal."
            >
              <InfoIcon aria-label="goal trigger" className="h-4 w-4 fill-current text-dodger-blue-300" />
            </Tooltip>
          </div>
        ) : null}
        <div className="w-full min-w-0">
          <span className="flex h-[50px] items-center justify-center">
            <WebP
              className="w-[64px]"
              webp={GOAL_TRIGGERS_METADATA[goal.icon.toUpperCase()]?.webp ?? GOAL_TRIGGERS_METADATA.OTHER.webp}
              fallback={GOAL_TRIGGERS_METADATA[goal.icon.toUpperCase()]?.png ?? GOAL_TRIGGERS_METADATA.OTHER.png}
              width="64"
              height="50"
              alt="goal"
            />
          </span>
          <div className="flex min-w-0 flex-col">
            <div className="mb-2 text-center">
              <div className="text-header-2">
                Goal:&nbsp;
                {!isSharing ? (
                  <Link to={panelLink} className="text-dodger-blue-500 hover:underline">
                    {panelTitle}
                  </Link>
                ) : (
                  panelTitle
                )}
              </div>
            </div>

            {goal && goalDisplayData ? (
              <>
                <GoalTriggerSummary goal={goal} centered={true} showMaxTriggers={1} expandBehavior="noshow" />
                {(goalDisplayData?.triggers ?? []).length > 1 ? (
                  <button
                    type="button"
                    className="mt-2.5 text-xs font-semibold text-dodger-blue-500"
                    onClick={() => modal.show(<GoalTriggersModal goal={goal} goalDisplayData={goalDisplayData} />)}
                  >
                    Show {goalDisplayData.triggers.length - 1} more triggers
                  </button>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        <div className="mt-4 text-center text-md">
          Total conversions: <span className="font-bold">{data?.totalConversions ?? 0}</span>
        </div>
      </Panel>
    </section>
  );
}
