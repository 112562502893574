import { Link } from 'react-router-dom';
import { Button, UsageBar, Tooltip } from '@crazyegginc/hatch';

import { useMetric, useAuthContext, useNeedsUpgradeToAccessFeature } from '/src/hooks';
import { SectionHeader } from '../components/accountUI';

import { MANAGE_SUBSCRIPTION_PATH, FEATURES } from '/src/features/_global/constants';

import { ReactComponent as LockIcon } from '@crazyegginc/hatch/dist/images/icon-lock-filled.svg';

export function PlanDetails({ currentPlanTitle }) {
  const { capabilities, subscription } = useAuthContext();

  const isStarterPlan = subscription.plan.name.toLowerCase().includes('starter');

  const storageDuration = capabilities.recordings.quotas.storageDuration.limit;

  const visitsLimit = capabilities.account.quotas.monthlyPageViews.limit;
  const visits = capabilities.account.quotas.monthlyPageViews.current;

  const recordingsUsed = capabilities.recordings.quotas.monthlyRecordings.current;
  const recordingsLimit = capabilities.recordings.quotas.monthlyRecordings.limit;
  const snapshotsUsed = capabilities.snapshots.quotas.totalSnapshots.current;
  const snapshotsLimit = capabilities.snapshots.quotas.totalSnapshots.limit;

  return (
    <>
      <SectionHeader className="mt-10">Plan Details and Usage</SectionHeader>
      <div className="flex w-full space-x-5">
        <section className="flex w-2/3 rounded-md border-2 border-mystic-500 px-5 py-[30px]">
          <div className="flex w-full space-x-5">
            <div className="w-1/2 flex-col space-y-4">
              <PlanDetailsRow
                title="Pageviews"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.SNAPSHOTS)}
                value={`${(visitsLimit || 0).toLocaleString()} /month`}
                currentPlanTitle={currentPlanTitle}
              />
              <PlanDetailsRow
                title="Snapshots"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.SNAPSHOTS)}
                value={(snapshotsLimit || 0).toLocaleString()}
                currentPlanTitle={currentPlanTitle}
              />
              <PlanDetailsRow
                title="Recordings"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.RECORDINGS)}
                value={`${(recordingsLimit || 0).toLocaleString()} /month ${
                  storageDuration != null ? `(${storageDuration} months storage)` : ''
                }`}
                currentPlanTitle={currentPlanTitle}
              />
              <PlanDetailsRow
                title="Goals"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.GOALS)}
                currentPlanTitle={currentPlanTitle}
                isStarterPlan={isStarterPlan}
              />
              <PlanDetailsRow
                title="A/B Tests"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.AB_TESTING)}
                currentPlanTitle={currentPlanTitle}
                isStarterPlan={isStarterPlan}
              />
              <PlanDetailsRow
                title="Traffic Analysis"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.TRAFFIC_ANALYSIS)}
                value="based on recorded sessions"
                currentPlanTitle={currentPlanTitle}
              />
            </div>
            <div className="w-1/2 flex-col space-y-4">
              <PlanDetailsRow
                title="Errors Tracking"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.ERRORS_TRACKING)}
                value="based on recorded sessions"
                currentPlanTitle={currentPlanTitle}
                isStarterPlan={isStarterPlan}
              />
              <PlanDetailsRow
                title="Surveys"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.SURVEYS)}
                currentPlanTitle={currentPlanTitle}
                value="unlimited with unlimited responses"
              />
              <PlanDetailsRow
                title="CTAs"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.CTAS)}
                currentPlanTitle={currentPlanTitle}
                isStarterPlan={isStarterPlan}
              />
              <PlanDetailsRow
                title="Team Members"
                locked={useNeedsUpgradeToAccessFeature(FEATURES.TEAM_AND_SHARING)}
                currentPlanTitle={currentPlanTitle}
              />
              <PlanDetailsRow title="Websites" currentPlanTitle={currentPlanTitle} />
            </div>
          </div>
        </section>

        <section className="relative flex w-1/3 flex-col justify-center space-y-5 rounded-md border-2 border-mystic-500 px-5 py-[30px]">
          {!useNeedsUpgradeToAccessFeature(FEATURES.SNAPSHOTS) ? (
            <FeatureUsageMeter feature="pageviews" used={visits} limit={visitsLimit} />
          ) : (
            <LockedUsageBar feature="pageviews" />
          )}

          {!useNeedsUpgradeToAccessFeature(FEATURES.SNAPSHOTS) ? (
            <FeatureUsageMeter feature="Snapshots" used={snapshotsUsed} limit={snapshotsLimit} />
          ) : (
            <LockedUsageBar feature="Snapshots" />
          )}

          {!useNeedsUpgradeToAccessFeature(FEATURES.RECORDINGS) ? (
            <FeatureUsageMeter feature="Recordings" used={recordingsUsed} limit={recordingsLimit} />
          ) : (
            <LockedUsageBar feature="Recordings" />
          )}
        </section>
      </div>
    </>
  );
}

function PlanDetailsRow({ title, value = 'unlimited', locked = false, currentPlanTitle, isStarterPlan = false }) {
  const trackMetric = useMetric();
  if (isStarterPlan) {
    return (
      <div className="flex">
        <div className="mr-3 w-24 shrink-0 text-xs font-normal text-black-pearl-500 2xl:text-sm">{title}</div>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className="text-link leading-tight"
          to={MANAGE_SUBSCRIPTION_PATH}
          onMouseDown={() =>
            trackMetric('upgrade_cta_click', `plan_locked_usage_${title.toLowerCase().replace(/\/| /g, '_')}`)
          }
        >
          upgrade to access
        </Link>
      </div>
    );
  }

  return (
    <div className="flex">
      <div className="mr-3 w-24 shrink-0 text-xs font-normal text-black-pearl-500 2xl:text-sm">{title}</div>
      {locked ? (
        <div className="flex items-center text-xs font-normal text-lynch-500 2xl:text-sm">
          Not available on {currentPlanTitle} plan
          <LockIcon className="ml-1.5 h-4 w-4 shrink-0 fill-current text-cadet-blue-500" />
        </div>
      ) : (
        <div className="text-xs font-semibold text-black-pearl-500 2xl:text-sm">{value}</div>
      )}
    </div>
  );
}

function FeatureUsageMeter({ feature, used, limit }) {
  // If 0 divided by 0, will return NaN
  const usage = Math.min(Math.floor((used / limit) * 100), 100) || 0;

  return (
    <div className="text-body-2 w-full text-xs">
      <div className="mb-2.5 flex justify-between">
        <span>
          {usage}% of {feature} used
        </span>
        <span>
          {(used || 0).toLocaleString()} / {(limit || 0).toLocaleString()}
        </span>
      </div>
      <UsageBar usage={usage} size="lg" />
    </div>
  );
}

function LockedUsageBar({ feature }) {
  const trackMetric = useMetric();
  return (
    <div className="text-body-5 w-full text-xs">
      <Tooltip
        placement="bottom"
        arrowSkiddingPercent="15"
        skiddingPercent="20"
        interactive={true}
        tooltipContent={
          <div className="mb-2.5 ml-2 mr-5 mt-4 flex max-w-[230px] flex-col text-left">
            Upgrade to a paid plan to access {feature}.
            <Button
              size="xs"
              theme="dark"
              className="!mt-2.5 !self-start"
              component={Link}
              to={MANAGE_SUBSCRIPTION_PATH}
              onMouseDown={() => trackMetric('upgrade_cta_click', `plan_locked_usage_${feature.toLowerCase()}`)}
            >
              Upgrade Now
            </Button>
          </div>
        }
      >
        <div className="mb-2.5 flex items-center">
          <span className="mt-px">0% of {feature} used</span>
          <LockIcon className="ml-1.5 h-4 w-4 shrink-0 fill-current text-cadet-blue-500" />
        </div>
        <UsageBar usage={0} size="lg" />
      </Tooltip>
    </div>
  );
}
