import { useState } from 'react';
import { Button } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';

export function RetireVariantModal({ variant, onConfirm = null }) {
  const [retiring, setRetiring] = useState(false);

  return (
    <Modal>
      <Modal.Title>Are you sure?</Modal.Title>
      <div className="text-body-2 leading-normal">
        If you delete this variant, it will no longer be shown to visitors.
      </div>

      <Modal.Actions>
        <Button
          disabled={retiring}
          variant="warning"
          onClick={async () => {
            setRetiring(true);
            await onConfirm?.(variant);
            setRetiring(false);
          }}
        >
          {retiring ? 'Retiring...' : 'Retire variant'}
        </Button>
        <Modal.Cancel>Keep this variant</Modal.Cancel>
      </Modal.Actions>
    </Modal>
  );
}
