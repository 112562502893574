import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import Select, { createFilter, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { produce } from 'immer';
import { useQuery } from '@tanstack/react-query';
import { Select as SimpleSelect, Tag, getTagColor, Tooltip, Indicator } from '@crazyegginc/hatch';

import { useSelectedSite, useDebounce } from '/src/hooks';

import { goalListMinimalQuery } from '/src/features/goals/queries';
import { recordingsTagsQuery } from '/src/features/recordings/queries';
import { addonListQuery } from '/src/features/addons/queries';
import { TooltipContainer } from './FilterCondition';

import { ADDON_TYPES } from '/src/features/addons/constants';

import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';
import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as GoalIcon } from '@crazyegginc/hatch/dist/images/icon-goal-filled.svg';
import { ReactComponent as CTAIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';

export function FilterSelect({ options, value, onChange, error, label, placeholder = null, containerStyle = {} }) {
  if (!value && !placeholder) {
    value = options[0].value;
  }

  return (
    <div className="m-1.25 ml-0 min-w-[90px]" style={{ ...containerStyle }}>
      <SimpleSelect
        value={value}
        options={options}
        error={error}
        onChange={onChange}
        placeholder={placeholder}
        aria-label={label}
      />
    </div>
  );
}

export function FilterMultiSelect({
  options,
  value,
  onChange,
  error,
  label,
  tooltip,
  onTooltipDismiss,
  placeholder = null,
  containerStyle = {},
}) {
  const ref = useRef();

  useEffect(() => {
    if (tooltip) {
      ref.current?.controlRef?.scrollIntoView({ block: 'center' });
    }
  }, [tooltip]);

  const render = () => (
    <Select
      isMulti
      options={options}
      value={value}
      getOptionLabel={(option) => option.display_name ?? option.value} // TODO: add icon for future
      getOptionValue={(option) => option.value}
      placeholder={placeholder}
      filterOption={createFilter({ ignoreCase: true })}
      components={componentOverwrites}
      onChange={onChange}
      containerStyle={containerStyle}
      error={error}
      styles={{
        ...filterSelectCommonStyles,
        ...filterSelectMultiStyles,
      }}
      aria-label={label}
      ref={ref}
      tabSelectsValue={false}
    />
  );

  return (
    <div className="flex flex-col">
      {tooltip ? (
        <Tooltip
          hover={false}
          placement="bottom"
          skiddingPercent="80"
          arrowSkiddingPercent="20"
          offset="0"
          onDismiss={onTooltipDismiss}
          tooltipContent={<TooltipContainer>{tooltip}</TooltipContainer>}
        >
          {render()}
        </Tooltip>
      ) : (
        render()
      )}
      {error && (
        <div className="relative h-5">
          <div className="text-body-4 absolute -translate-y-1 whitespace-nowrap text-carnation-500">{error}</div>
        </div>
      )}
    </div>
  );
}

const minSearchChars = 0;

export function FilterTagsSelect({
  value,
  onChange,
  error,
  label,
  tooltip,
  onTooltipDismiss,
  placeholder = null,
  containerStyle = {},
  creatable = false,
  inputLimit = 30,
  inlineStyle = false,
  playerStyle = false,
  isDisabled = false,
  ...rest
}) {
  const { selectedSite } = useSelectedSite();
  const [input, setInput] = useState('');
  const [paused, setPaused] = useState(true);
  const debouncedInput = useDebounce(input, 300);

  let ref = useRef();

  useEffect(() => {
    if (tooltip) {
      ref.current?.controlRef?.scrollIntoView({ block: 'center' });
    }
  }, [tooltip]);

  const { data, isFetching } = useQuery({
    ...recordingsTagsQuery({
      fragment: debouncedInput,
      site: selectedSite?.id,
      limit: 200,
    }),
    enabled: Boolean(debouncedInput.length >= minSearchChars && selectedSite?.id && !paused),
  });

  const setInputWithLimit = (newValue) => {
    if (newValue.length <= inputLimit) {
      setInput(newValue);
    } else {
      setInput(newValue.substring(0, inputLimit));
    }
  };

  const options =
    debouncedInput.length < minSearchChars ? [] : data?.recordingsTags.map((tag) => ({ value: tag, label: tag }));

  const render = () =>
    creatable ? (
      <CreatableSelect
        onInputChange={(newValue) => setInputWithLimit(newValue)}
        inputValue={input}
        noOptionsMessage={() =>
          debouncedInput.length < minSearchChars ? 'Start typing to see options' : 'No tags matching'
        }
        isMulti
        isLoading={isFetching}
        options={options}
        value={value}
        getOptionLabel={(o) => {
          let option = o?.label ?? o;
          if (option.match(/ - new tag/)) {
            return (
              <div
                className={classNames('text-body-2 font-xs flex h-full w-full items-center hover:font-semibold', {
                  'text-cadet-blue-500': playerStyle,
                })}
              >
                {option}
              </div>
            );
          }
          return <Tag tag={option} tagClass="!mt-0 !mr-0 !mb-0 !ml-0" />;
        }}
        getOptionValue={(option) => option?.value ?? option}
        placeholder={placeholder}
        filterOption={createFilter({ ignoreCase: true })}
        Icon={<TagIcon className="h-4 w-4 fill-current text-black-pearl-500" aria-label="tag icon" />}
        components={
          inlineStyle
            ? { ...playerStyleComponentOverwrites(isDisabled) }
            : { ...componentOverwrites, Control: ControlComponent }
        }
        onChange={onChange}
        containerStyle={containerStyle}
        error={error}
        inlineStyle={inlineStyle}
        styles={playerStyle ? filterSelectTagPlayerStyles : filterSelectTagStyles}
        aria-label={label}
        ref={ref}
        tabSelectsValue={false}
        createOptionPosition="first"
        allowCreateWhileLoading={true}
        getNewOptionData={(inputValue, optionLabel) => ({ value: inputValue, label: optionLabel })}
        formatCreateLabel={(inputValue) => `${inputValue} - new tag`}
        isValidNewOption={(inputValue, selectValue, selectOptions) => {
          return (
            inputValue.length &&
            !selectOptions.includes(inputValue.toLowerCase()) &&
            !selectValue.includes(inputValue.toLowerCase())
          );
        }}
        isDisabled={isDisabled}
        onMenuOpen={() => (paused ? setPaused(false) : null)}
        {...rest}
      />
    ) : (
      <Select
        onInputChange={(newValue) => setInput(newValue)}
        noOptionsMessage={() =>
          debouncedInput.length < minSearchChars ? 'Start typing to see options' : 'No tags matching'
        }
        isMulti
        isLoading={isFetching}
        options={options}
        value={value}
        getOptionLabel={(option) => {
          return <Tag tag={option?.label ?? option} tagClass="!mt-0 !mr-0 !mb-0 !ml-0" />;
        }}
        getOptionValue={(option) => option?.value ?? option}
        placeholder={placeholder}
        filterOption={createFilter({ ignoreCase: true })}
        Icon={<TagIcon className="h-4 w-4 fill-current text-black-pearl-500" aria-label="tag icon" />}
        components={
          inlineStyle
            ? { ...playerStyleComponentOverwrites(isDisabled) }
            : { ...componentOverwrites, Control: ControlComponent }
        }
        onChange={onChange}
        containerStyle={containerStyle}
        error={error}
        styles={playerStyle ? filterSelectTagPlayerStyles : filterSelectTagStyles}
        aria-label={label}
        ref={ref}
        tabSelectsValue={false}
        isDisabled={isDisabled}
        onMenuOpen={() => (paused ? setPaused(false) : null)}
        {...rest}
      />
    );

  return (
    <div className="flex flex-col">
      {tooltip ? (
        <Tooltip
          hover={false}
          placement="bottom"
          skiddingPercent="80"
          arrowSkiddingPercent="20"
          offset="0"
          onDismiss={onTooltipDismiss}
          tooltipContent={<TooltipContainer>{tooltip}</TooltipContainer>}
        >
          {render()}
        </Tooltip>
      ) : (
        render()
      )}
      {error && (
        <div className="relative h-5">
          <div className="text-body-4 absolute -translate-y-1 whitespace-nowrap text-carnation-500">{error}</div>
        </div>
      )}
    </div>
  );
}

export function FilterGoalsSelect({
  value,
  onChange,
  error,
  label,
  tooltip,
  onTooltipDismiss,
  placeholder = null,
  ...rest
}) {
  const { selectedSite } = useSelectedSite();
  const ref = useRef();

  const { data, isFetching } = useQuery({
    ...goalListMinimalQuery({
      siteId: selectedSite?.id,
      limit: -1,
    }),
    enabled: Boolean(selectedSite?.id),
  });

  useEffect(() => {
    if (tooltip) {
      ref.current?.controlRef?.scrollIntoView({ block: 'center' });
    }
  }, [tooltip]);

  const options = data?.goalList?.list?.map?.((goal) => ({ value: String(goal.id), label: goal.name })) ?? [];

  const render = () => (
    <Select
      noOptionsMessage={() => 'No Goals matching'}
      isMulti
      isLoading={isFetching}
      options={options}
      value={value?.map?.((val) => String(val)) ?? null}
      formatOptionLabel={(option) => (
        <Tag
          tag={option?.label ?? options.find((o) => o.value === option)?.label}
          tagClass="!mt-0 !mr-0 !mb-0 !ml-0 !bg-[#6565E0] !text-white"
        />
      )}
      getOptionValue={(option) => option.value ?? options.find((o) => o.value === option)?.value ?? option}
      placeholder={placeholder}
      Icon={<GoalIcon className="h-4 w-4 fill-current text-black-pearl-500" aria-label="goal icon" />}
      components={{ ...componentOverwrites, Control: ControlComponent }}
      onChange={onChange}
      error={error}
      styles={filterSelectStyles('#6565E0')}
      aria-label={label}
      tabSelectsValue={false}
      isDisabled={false}
      isOptionSelected={(option) => value.includes(parseInt(option.value))}
      hideSelectedOptions={true}
      ref={ref}
      {...rest}
    />
  );

  return (
    <div className="flex flex-col">
      {tooltip ? (
        <Tooltip
          hover={false}
          placement="bottom"
          skiddingPercent="80"
          arrowSkiddingPercent="20"
          offset="0"
          onDismiss={onTooltipDismiss}
          tooltipContent={<TooltipContainer>{tooltip}</TooltipContainer>}
        >
          {render()}
        </Tooltip>
      ) : (
        render()
      )}

      {error && (
        <div className="relative h-5">
          <div className="text-body-4 absolute -translate-y-1 whitespace-nowrap text-carnation-500">{error}</div>
        </div>
      )}
    </div>
  );
}

export function FilterCTASelect({
  value,
  onChange,
  error,
  label,
  tooltip,
  onTooltipDismiss,
  placeholder = null,
  ...rest
}) {
  const { selectedSite } = useSelectedSite();
  const ref = useRef();

  const { data, isFetching } = useQuery({
    ...addonListQuery({
      siteId: selectedSite?.id,
      filter: {
        limit: -1,
        query: null,
        type: ADDON_TYPES.CTA,
      },
      minimal: true,
    }),
    enabled: Boolean(selectedSite?.id),
  });

  useEffect(() => {
    if (tooltip) {
      ref.current?.controlRef?.scrollIntoView({ block: 'center' });
    }
  }, [tooltip]);

  const options =
    data?.addonList?.list?.filter((cta) => cta.publishedAt).map((cta) => ({ value: cta.id, label: cta.name })) ?? [];

  const render = () => (
    <Select
      noOptionsMessage={() => `No CTA matching`}
      isMulti
      isLoading={isFetching}
      options={options}
      value={value}
      formatOptionLabel={(option) => (
        <Tag
          tag={option?.label ?? options.find((o) => o.value === option)?.label}
          tagClass="!mt-0 !mr-0 !mb-0 !ml-0 !bg-[#1698A9] !text-white"
        />
      )}
      getOptionValue={(option) => option.value ?? options.find((o) => o.value === option)?.value ?? option}
      placeholder={placeholder}
      Icon={<CTAIcon className="h-4 w-4 fill-current text-black-pearl-500" aria-label="cta icon" />}
      components={{ ...componentOverwrites, Control: ControlComponent }}
      onChange={onChange}
      error={error}
      styles={filterSelectStyles('#1698A9')}
      aria-label={label}
      tabSelectsValue={false}
      isDisabled={false}
      isOptionSelected={(option) => value.includes(option.value)}
      hideSelectedOptions={true}
      ref={ref}
      {...rest}
    />
  );

  return (
    <div className="flex flex-col">
      {tooltip ? (
        <Tooltip
          hover={false}
          placement="bottom"
          skiddingPercent="80"
          arrowSkiddingPercent="20"
          offset="0"
          onDismiss={onTooltipDismiss}
          tooltipContent={<TooltipContainer>{tooltip}</TooltipContainer>}
        >
          {render()}
        </Tooltip>
      ) : (
        render()
      )}

      {error && (
        <div className="relative h-5">
          <div className="text-body-4 absolute -translate-y-1 whitespace-nowrap text-carnation-500">{error}</div>
        </div>
      )}
    </div>
  );
}

function playerStyleComponentOverwrites(isDisabled) {
  return {
    ClearIndicator: () => null,
    IndicatorSeparator: () => null,
    DropdownIndicator: () => null,
    MultiValueRemove: (props) => (isDisabled ? null : <MultiValueRemove {...props} />),
  };
}

const componentOverwrites = {
  ClearIndicator: () => null,
  IndicatorSeparator: () => null,
  DropdownIndicator: (props) => <DropdownIndicator {...props} />,
  MultiValueRemove: (props) => <MultiValueRemove {...props} />,
};

function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <div className="relative h-full w-2.5 text-black-pearl-500 hover:text-carnation-500">
        <CrossIcon className="absolute bottom-1/2 left-0 h-[7px] w-[7px] translate-y-1/2 fill-current" />
      </div>
    </components.MultiValueRemove>
  );
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <div className="h-2.5 w-2.5">
        <Indicator type="dropdown" className="text-black-pearl-500" />
      </div>
    </components.DropdownIndicator>
  );
}

function ControlComponent({ children, ...props }) {
  const { Icon } = props.selectProps;

  return (
    <components.Control {...props}>
      {Icon}
      {children}
    </components.Control>
  );
}

/* ========= COMMON ========= */
const filterSelectCommonStyles = {
  placeholder: (base) => ({
    ...base,
    color: '#68768d',
  }),
  menu: (base) => ({
    ...base,
    width: 'auto',
    whiteSpace: 'nowrap',
    border: '1px solid #E1E6EF',
    borderRadius: '2px',
    boxShadow: '0 4px 6px 0 rgba(7,31,49,0.17)',
    padding: '0px 10px',
    zIndex: '12',
  }),
  option: (base, state) => ({
    ...base,
    color: '#0a1c2e',
    fontSize: '14px',
    // use textShadow instead of bold text to avoid container width change on hover. Needs testing, if looks weird we need another solution
    textShadow: state.isFocused ? '-0.05ex 0 #0a1c2e, 0.05ex 0 #0a1c2e' : 0,
    backgroundColor: state.isFocused ? '#e5f2fc' : '#FFFFFF',
    '&:hover': {
      backgroundColor: '#e5f2fc',
      textShadow: '-0.05ex 0 #0a1c2e, 0.05ex 0 #0a1c2e',
    },
  }),
  indicatorsContainer: (base) => ({
    ...base,
    '& div': {
      padding: '0 10px 0 0',
    },
  }),
};

/* ========= MULTI ========= */
const filterSelectMultiStyles = {
  container: (base, { selectProps: { containerStyle } }) => ({
    ...base,
    margin: '5px 5px 5px 0',
    fontSize: '13px',
    minWidth: '200px',
    ...containerStyle,
  }),
  control: (base, { selectProps: { error } }) => ({
    ...base,
    minHeight: '35px',
    border: error ? '1px solid #EC5D58' : '1px solid #E1E6EF',
    boxShadow: 'none',
    borderRadius: '2px',
    '&:hover': {
      borderColor: '#E1E6EF',
      cursor: 'pointer',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#ECEDF1',
    borderRadius: '10px',
    height: '20px',
    overflow: 'hidden',
  }),
  multiValueRemove: (base) => ({
    ...base,
    '&:hover': {
      background: 'transparent',
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontSize: '12px',
    color: '#0a1c2e ',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    fontWeight: 600,
  }),
};

/* ========= TAG ========= */
const filterSelectTagStyles = {
  control: (base, { selectProps: { error, inlineStyle } }) => ({
    ...base,
    minHeight: '35px',
    paddingLeft: inlineStyle ? '0px' : '10px',
    border: error ? '1px solid #EC5D58' : '1px solid #E1E6EF',
    boxShadow: 'none',
    borderRadius: '2px',
    '&:hover': {
      borderColor: '#E1E6EF',
      cursor: 'pointer',
    },
  }),
  option: (base, state) => ({
    ...base,
    display: 'flex',
    height: '26px',
    padding: '3px 5px',
    backgroundColor: state.isSelected || state.isFocused ? '#e5f2fc' : '#FFFFFF',
    '&:hover': {
      backgroundColor: '#e5f2fc',
    },
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: '99999',
  }),
  menu: (base) => ({
    ...base,
    border: '1px solid #E1E6EF',
    borderRadius: '2px',
    boxShadow: '0 4px 6px 0 rgba(7,31,49,0.17)',
    padding: '0px 10px',
    zIndex: '12',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    '& div': {
      padding: '0 10px 0 0',
    },
  }),
  container: (base, { selectProps: { containerStyle } }) => ({
    ...base,
    margin: '5px 5px 5px 0',
    fontSize: '13px',
    minWidth: '315px',
    ...containerStyle,
  }),
  multiValue: (base, { data }) => ({
    ...base,
    backgroundColor: getTagColor(data?.value ?? data).background,
    borderRadius: '10px',
    height: '20px',
    overflow: 'hidden',
    padding: 0,
  }),
  multiValueRemove: (base, { data }) => ({
    ...base,
    '& div::before,& div::after': {
      borderTop: `1px solid ${getTagColor(data?.value ?? data).color}`,
    },
    '&:hover': {
      '& div::before,& div::after': {
        borderTop: '1px solid #ec5d58',
      },
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    fontWeight: 600,
  }),
};

const filterSelectTagPlayerStyles = {
  placeholder: (base) => ({
    ...base,
    color: '#a5acbc',
  }),
  input: (base) => ({
    ...base,
    color: '#fff',
  }),
  control: (base) => ({
    ...base,
    minHeight: '35px',
    paddingLeft: '0',
    border: '1px solid #3c434d',
    boxShadow: 'none',
    borderRadius: '2px',
    background: 'rgba(10,11,14,0.94)',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  option: (base, state) => ({
    ...base,
    display: 'flex',
    height: '26px',
    padding: '3px 5px',
    backgroundColor: state.isSelected || state.isFocused ? '#1C212C' : '#12161C',
    '&:hover': {
      backgroundColor: '#1C212C',
    },
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: '99999',
  }),
  menu: (base) => ({
    ...base,
    background: '#12161C',
    border: '1px solid #222833',
    borderRadius: '2px',
    boxShadow: '0 4px 6px 0 rgba(7,31,49,0.17)',
    padding: '0px 10px',
    zIndex: '12',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    '& div': {
      padding: '0 10px 0 0',
    },
  }),
  container: (base, { selectProps: { containerStyle } }) => ({
    ...base,
    margin: '5px 5px 5px 0',
    fontSize: '13px',
    minWidth: '315px',
    ...containerStyle,
  }),
  multiValue: (base, { data, selectProps: { isDisabled } }) => ({
    ...base,
    backgroundColor: getTagColor(data).background,
    borderRadius: '10px',
    height: '20px',
    overflow: 'hidden',
    padding: 0,
    paddingRight: isDisabled ? '6px' : 0,
  }),
  multiValueRemove: (base, { data }) => ({
    ...base,
    '& div::before,& div::after': {
      borderTop: `1px solid ${getTagColor(data).color}`,
    },
    '&:hover': {
      '& div::before,& div::after': {
        borderTop: '1px solid #ec5d58',
      },
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    fontWeight: 600,
  }),
};

/* ========= GOAL / CTA ========= */
function filterSelectStyles(color) {
  return produce(filterSelectTagStyles, (draft) => {
    draft.multiValue = (base, { selectProps: { isDisabled } }) => ({
      ...base,
      backgroundColor: color,
      borderRadius: '10px',
      height: '20px',
      overflow: 'hidden',
      padding: 0,
      paddingRight: isDisabled ? '6px' : 0,
    });
    draft.multiValueRemove = (base) => ({
      ...base,
      '& div::before,& div::after': {
        borderTop: `1px solid ${color}`,
      },
      '&:hover': {
        '& div::before,& div::after': {
          borderTop: '1px solid #ec5d58',
        },
      },
    });
  });
}
