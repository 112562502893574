import { Divider, Tooltip, SkeletonLine, DeviceIcon } from '@crazyegginc/hatch';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { LazyImg } from '/src/components/LazyImg';
import { displayUrl } from '/src/utils/url';

import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as ImgPlaceholderIcon } from '@crazyegginc/hatch/dist/images/illustration-thumbnail-placeholder.svg';

import { computedStatus } from '../pages/index_v2';

import { AB_TEST_STATUSES } from '/src/features/ab-testing/constants';

function RenderLazyImg({ srcUrl }) {
  return (
    <LazyImg
      className="h-[88px] w-[138px] rounded-none rounded-t object-cover"
      alt="item thumbnail"
      src={srcUrl}
      Placeholder={ImgPlaceholderIcon}
      width="138px"
      height="88px"
    />
  );
}
export function ListCard({ abTest = null, counters = null, actionButton = null, primaryButton = null, children }) {
  const { name, status, permissions } = abTest;
  const hasErrors = false; // https://app.shortcut.com/crazyegg/story/33768/a-b-test-hide-error-status
  const canViewResults = permissions.canViewResults;

  const resultsPath = `/ab-tests/${abTest.id}`;
  const editPath = `/ab-tests/${abTest.id}/edit`;

  const isDraft = abTest.status === AB_TEST_STATUSES.DRAFT;
  const isStopped = abTest.status === AB_TEST_STATUSES.STOPPED;

  const viewPath = isDraft ? editPath : resultsPath;

  const humanizedStatus = computedStatus(status);

  return (
    <div className="flex w-full min-w-[650px] justify-between space-x-6 rounded border border-mystic-500 bg-white p-6 shadow">
      <div className="flex min-w-[140px] flex-col">
        <div className="flex flex-col rounded border border-mystic-500">
          <Link to={viewPath}>
            <RenderLazyImg srcUrl={abTest?.screenshotUrl ?? null} />
          </Link>
          <Tooltip
            show={!isStopped && !isDraft && hasErrors}
            style={{ maxWidth: '370px' }}
            interactive={canViewResults}
            placement="bottom"
            tooltipContent={
              <div className="flex w-[370px] flex-col space-y-2 p-[25px] text-left">
                <h1 className="text-header-2 m-0 text-white">Variants with errors</h1>
                <p className="text-body-2 text-white">
                  One or more of the variants in this test appear to have errors. This means that an edit you’ve
                  requested could not successfully be made to your site.
                </p>
                <p className="text-body-2 text-white">
                  {canViewResults ? (
                    <Link to={viewPath} className="text-link">
                      View results
                    </Link>
                  ) : (
                    'View results'
                  )}{' '}
                  to identify the variants with errors and retire them.
                </p>
              </div>
            }
          >
            {status ? (
              <span
                className={classNames(
                  'flex items-center justify-center pb-2.5 pt-4 text-center text-2xs font-semibold capitalize',
                  {
                    'text-california-500': status === AB_TEST_STATUSES.STOPPED,
                    'text-lima-500':
                      !hasErrors && [AB_TEST_STATUSES.PENDING, AB_TEST_STATUSES.RUNNING].includes(status),
                    'text-caption': !hasErrors && status === AB_TEST_STATUSES.DRAFT,
                    'text-carnation-500': hasErrors && status !== AB_TEST_STATUSES.STOPPED,
                  },
                )}
              >
                <span className="flex items-baseline rounded bg-white-lilac-500 px-[5px] py-px">
                  {status === AB_TEST_STATUSES.STOPPED ? (
                    <div className="mr-2.5 h-2.5 w-2.5 bg-california-500" />
                  ) : null}
                  {status === AB_TEST_STATUSES.DRAFT ? (
                    <div className="mr-2.5 h-[6px] w-[6px] rounded-full bg-current" />
                  ) : null}
                  {!hasErrors && [AB_TEST_STATUSES.RUNNING, AB_TEST_STATUSES.PENDING].includes(status) ? (
                    <PlayIcon className="mr-2.5 h-2.5 w-2.5 fill-current text-lima-500" />
                  ) : null}
                  {hasErrors && status !== AB_TEST_STATUSES.STOPPED ? (
                    <ErrorIcon className="mr-2.5 h-2.5 w-2.5 fill-current text-carnation-500" />
                  ) : null}

                  <span className="mt-px">
                    {hasErrors && status !== AB_TEST_STATUSES.STOPPED
                      ? 'error'
                      : isStopped
                        ? 'Ended'
                        : humanizedStatus?.toLowerCase?.()}
                  </span>
                </span>
              </span>
            ) : null}
          </Tooltip>
        </div>

        <div className="mt-[6px] text-center">
          <span className="text-body-4 text-cadet-blue-500">ID {abTest.id}</span>
        </div>
      </div>
      <div className="flex min-w-0 flex-1 flex-col">
        <div className="flex justify-between">
          <div className="flex flex-col truncate">
            <Link to={viewPath} className="min-w-0">
              <h2 className="text-header-3 min-w-0 flex-1 truncate hover:underline">{name}</h2>
            </Link>
            <span className="text-body-5">{displayUrl(abTest.matchingUrl)}</span>
          </div>
          <div className="ml-auto flex shrink-0 space-x-2">
            {actionButton ? actionButton : null}
            {primaryButton ? primaryButton : null}
          </div>
        </div>

        <Divider className="mb-5 mt-[14px] !border-t-2 !border-solitude-500" />

        <div className="flex flex-1">
          <div className="flex min-w-0 flex-1">{children}</div>
          <div className="flex space-x-[35px] pr-4">{counters}</div>
        </div>
      </div>
    </div>
  );
}

export function ListCardMetric({ value, title = null, tooltipContent = null, interactive = false, unit, className }) {
  function MetricContent() {
    return (
      <section
        className={classNames(`flex flex-col items-center font-semibold text-dodger-blue-500/80`, className)}
        title={title}
        aria-label={title || `${value} ${unit}`}
      >
        <span className="text-xl">{value}</span>
        <span className="text-2xs uppercase">{unit}</span>
      </section>
    );
  }

  return (
    <div>
      {tooltipContent ? (
        <Tooltip tooltipContent={tooltipContent} interactive={interactive} placement="bottom">
          <MetricContent />
        </Tooltip>
      ) : (
        <MetricContent />
      )}
    </div>
  );
}

function getDevicesString(devices) {
  if (devices.length === 3) {
    return 'All devices';
  }
  return devices.join(' & ');
}

export function ListCardDevices({ devices = [], className = '' }) {
  const devicesString = getDevicesString(devices);
  const devicesIcons = devices.map((device) => (
    <DeviceIcon device={device} key={device} className="h-[18px] w-[18px] text-dodger-blue-500/80" />
  ));

  return (
    <div>
      <section
        className={classNames(
          `mt-[4px] flex max-w-[75px] flex-col items-center font-semibold text-dodger-blue-500/80`,
          className,
        )}
      >
        <div className="flex space-x-1.5">{devicesIcons}</div>
        <span className="mt-[8px] text-center text-2xs uppercase">{devicesString}</span>
      </section>
    </div>
  );
}

export function SkeletonCard() {
  return (
    <div className="flex w-full min-w-[650px] justify-between space-x-6 rounded border border-mystic-500 bg-white p-6 shadow">
      <div className="flex min-w-[140px] flex-col">
        <div className="flex flex-col rounded border border-mystic-500">
          <SkeletonLine className="!h-[135px] !rounded-none" width="100%" />
        </div>
      </div>
      <div className="flex min-w-0 flex-1 flex-col">
        <div className="flex justify-between">
          <h2 className="text-header-3 min-w-0 flex-1 truncate pt-1">
            <SkeletonLine />
          </h2>
          <div className="ml-auto flex space-x-2"></div>
        </div>

        <Divider className="my-5 !border-t-2 !border-solitude-500" />

        <div className="flex flex-1">
          <div className="flex flex-1 flex-col space-y-4">
            <SkeletonLine width="250px" />
            <SkeletonLine width="200px" />
            <SkeletonLine width="100px" />
            <SkeletonLine width="200px" />
          </div>
        </div>
      </div>
    </div>
  );
}
