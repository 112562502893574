import classNames from 'classnames';

export function AbTestRibbon({ text = null, variantType, hasBestImprovement = false }) {
  return (
    <div
      className={classNames('absolute z-10 py-px pl-3 pr-2 font-semibold', {
        'bg-lima-500 text-white': hasBestImprovement || variantType !== 'CONTROL',
        'bg-cadet-blue-500 text-off-white-500': !hasBestImprovement && variantType === 'CONTROL',
      })}
      style={{
        left: '-5px',
        width: '100px',
        top: '15px',
        fontSize: '12px',
        textTransform: 'uppercase',
        height: '20px',
      }}
    >
      {text}
      <span className="absolute overflow-hidden" style={{ top: '0px', right: '-20px', width: '20px', height: '20px' }}>
        <div
          className={classNames('-rotate-45', {
            'bg-lima-500 text-white': hasBestImprovement || variantType !== 'CONTROL',
            'bg-cadet-blue-500 text-mystic-500': !hasBestImprovement && variantType === 'CONTROL',
          })}
          style={{ height: '12px', width: '30px', transformOrigin: 'bottom left' }}
        ></div>
        <div
          className={classNames('rotate-45', {
            'bg-lima-500 text-white': hasBestImprovement || variantType !== 'CONTROL',
            'bg-cadet-blue-500 text-mystic-500': !hasBestImprovement && variantType === 'CONTROL',
          })}
          style={{
            height: '12px',
            width: '30px',
            transformOrigin: 'bottom left',
            position: 'relative',
            top: '-8px',
            left: '-10px',
          }}
        ></div>
      </span>
    </div>
  );
}
// border-style: solid;
//     border-color: red transparent red red;
//     top: 0;
//     right: -20
// px
// ;
//     display: block;
//     background: b;
//     border-width: 10
// px
// ;
