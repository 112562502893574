import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@crazyegginc/hatch';
import { ReactComponent as CopyIcon } from '@crazyegginc/hatch/dist/images/icon-pages-outline.svg';

import { copyToClipboard } from '/src/utils';
import { SupportLinks } from '/src/support';

export function Panel({ children }) {
  return (
    <div className="mb-[50px] box-border flex flex-col rounded border border-mystic-500 bg-white px-[35px] py-[30px] shadow-md">
      {children}
    </div>
  );
}

export function Label({ children, htmlFor, ...rest }) {
  return (
    <label
      htmlFor={htmlFor}
      {...rest}
      className="text-header-5 flex min-h-[40px] w-[140px] items-center justify-end self-start text-right leading-tight"
    >
      {children}
    </label>
  );
}

export function IntroText({ className }) {
  return (
    <p className={classNames('text-body-2', className)}>
      Single sign-on (SSO) allows anyone with an email from an approved domain to access Crazy Egg securely through an
      Identity Provider without the need for passwords.
      <br />
      Available for enterprise plans starting at <strong>$499/month</strong>.{' '}
      <a className="text-link" target="_blank" rel="noopener noreferrer" href={SupportLinks.general.newSupportRequest}>
        Contact us
      </a>{' '}
      to upgrade your plan or to add the SSO feature as an add-on to your existing plan.{' '}
      <a href={SupportLinks.sso} className="text-link" rel="noopener noreferrer">
        Learn more.
      </a>
    </p>
  );
}

export function ClipboardButton({ value }) {
  const [clicked, setClicked] = useState(false);

  const copyValue = useCallback(() => {
    copyToClipboard(value);
    setTimeout(() => {
      setClicked(false);
    }, 1500);
    setClicked(true);
  }, [value]);

  return (
    <Button variant="cancel" onClick={copyValue} className="absolute right-[2px] top-[2px] !px-2.5">
      <CopyIcon className="h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
      {clicked ? <span className="absolute left-1.25 top-7 text-[8px] uppercase">copied</span> : null}
    </Button>
  );
}
