import { formatDistance, fromUnixTime } from 'date-fns';
import classNames from 'classnames';
import { Panel, DeviceIcon } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { AdjustWeightsModal } from '/src/features/ab-testing/components/modals/AdjustWeights';

import { formatDate } from '/src/utils/date';
import { AB_TEST_STATUSES, abTestTypes } from '/src/features/ab-testing/constants';

import { ReactComponent as PencilIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

export function ABTestDetails({ data }) {
  const modal = useModal();
  const isDraft = data.status === AB_TEST_STATUSES.DRAFT;

  function getDuration() {
    if (isDraft) return 'Not yet launched.';
    const start = data?.publishedAt
      ? fromUnixTime(data.publishedAt)
      : data?.createdAt
        ? fromUnixTime(data.createdAt)
        : null;
    if (!start) return 'No data collected yet.';
    const end = data?.stoppedAt ? fromUnixTime(data.stoppedAt) : Date.now();
    if (data?.stoppedAt) {
      return `${formatDistance(start, end)} (${formatDate(data?.publishedAt || data?.createdAt)} - ${formatDate(
        data?.stoppedAt,
      )})`;
    }
    if (data?.publishedAt) {
      return `${formatDistance(start, end)} (Published ${formatDate(data?.createdAt)})`;
    }
    return `${formatDistance(start, end)} (Created ${formatDate(data?.createdAt)})`;
  }

  return (
    <section className="w-2/3">
      <div className="text-header-3 mb-3.75">Test details</div>

      <Panel className="!p-[20px]">
        <div className="w-full rounded bg-white-lilac-500">
          <Row title="Test Type">{data?.type === abTestTypes.PATCH ? 'Page editor' : 'URL redirect'}</Row>
          <Row title="Traffic split">
            {data?.autoReweight ? 'Multi-arm bandit - Powered by AI' : 'Manual'}
            {data?.permissions.canAdjustWeight ? (
              <button
                type="button"
                className="text-link ml-4 flex items-center leading-none"
                onClick={() => modal.show(<AdjustWeightsModal abTest={data} />)}
              >
                <PencilIcon className="mr-1 h-3 w-3 fill-current text-dodger-blue-500" />
                Edit
              </button>
            ) : null}
          </Row>
          {!isDraft ? <Row title="Visitors">{(data?.totalVisitors ?? 0).toLocaleString()}</Row> : null}
          <Row title="Run time">{getDuration()}</Row>
          <Row title="Device" className="space-x-3">
            {data?.devices.map((d) => (
              <DeviceIcon device={d} key={d} />
            ))}
          </Row>
          <Row title="Created by">{data?.createdByUserName ?? '-'}</Row>
        </div>
      </Panel>
    </section>
  );
}

function Row({ title, children, className }) {
  return (
    <div className="flex h-10 w-full items-center border-b border-dashed border-mystic-500 last:border-b-0">
      <div className="text-header-5 w-32 pl-4">{title}</div>
      <div className={classNames('text-body-5 flex items-center', className)}>{children}</div>
    </div>
  );
}
