import { useEffect, useMemo, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Tooltip, ReactTable, Spinner, SkeletonLine, IconButton } from '@crazyegginc/hatch';

import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { DashboardBanner } from '/src/components/DashboardBanner';
import { SitePickerBar } from '/src/components/site-picker/index';
import { DashboardPage } from '/src/components/Page';
import { LinkWithParams } from '/src/components/LinkWithParams';
import { Breadcrumb, BreadcrumbItem } from '/src/components/Breadcrumb';

import { useSite, useModal, useAuthContext, useNotifications, usePermissions } from '/src/hooks';

import { ABTestDetails } from '../components/ABTestDetails';
import { AbTestGoalCard } from '../components/AbTestGoalCard';
import { AbTestVariantsPanel } from '../components/ab-tests-variants-panel';
import { AbTestActionButton } from '../components/AbTestActionButton';

import { AddSplitVariant } from '../components/modals/AddSplitVariant';
import { EditSplitVariant } from '../components/modals/EditSplitVariant';
import { AbTestRenameModal } from '../components/modals/AbTestRenameModal';

import { parseDecimal, isProduction, isSharedResourceSubscriptionError } from '/src/utils';

import { SINGLE_SITE_QUERY, SITES_QUERY } from '/src/features/_global/queries';
import { abTestDetailQuery } from '../queries';
import { FEATURES } from '/src/features/_global/constants';
import { AB_TEST_STATUSES, abTestTypes } from '../constants';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

const isProd = isProduction();

function AbTestTopCards({ data }) {
  if (!data) return null;

  return (
    <span className="mb-10 flex items-stretch space-x-5">
      <ABTestDetails data={data} />
      <AbTestGoalCard data={data} />
    </span>
  );
}

function AbTestTableTextWinner(isWinner, text, improvement = 0) {
  return (
    <p
      className={classNames('text-body-2 capitalize', {
        'font-bold': isWinner,
        'text-lima-500': improvement > 0,
        'text-radical-red-500': improvement < 0,
        'text-black-pearl-500': improvement === 0,
      })}
    >
      {text ?? '-'}
    </p>
  );
}

function AbTestTable({ abTest, variants, fetching }) {
  const tableRef = useRef(null);
  const isDraft = abTest.status === AB_TEST_STATUSES.DRAFT;

  const columns = useMemo(
    () => [
      {
        header: 'Variants',
        accessorKey: 'variant',
        size: 150,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function VariantCell({ row }) {
          const text = row.original.variantName;
          return AbTestTableTextWinner(row.original.results.hasBestImprovement, text);
        },
      },
      {
        header: 'Traffic Split',
        accessorKey: 'trafficSplit',
        size: 120,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell: function TrafficSplitCell({ row }) {
          const { trafficSplit, visitors, conversions, hasBestImprovement } = row.original.results;
          if (abTest.autoReweight && !trafficSplit && !visitors && !conversions) {
            return <span className="text-body-3">pending</span>;
          }

          const text = `${parseDecimal(trafficSplit || 0, 2)}%`;
          return AbTestTableTextWinner(hasBestImprovement, text);
        },
      },
      {
        header: 'Visitors',
        accessorKey: 'visitor',
        size: 120,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell: function VisitorCell({ row }) {
          if (isDraft) return '-';
          return AbTestTableTextWinner(row.original.results.hasBestImprovement, row.original.results.visitors || 0);
        },
      },
      {
        header: function Conversion() {
          return (
            <div className="flex items-center">
              <span className="mr-1.5">Conversions</span>
              <Tooltip
                tooltipContent={
                  <div className="max-w-xs break-words p-2">
                    <h5 className="mb-2 text-xs font-bold text-white">
                      Does this look different to your Goal conversions?
                    </h5>
                    <p className="text-2xs">
                      Conversions for A/B tests are only counted if a visitor has landed on your test pages before
                      performing a goal, whereas all conversions are counted in your goals dashboard.
                    </p>
                  </div>
                }
              >
                <div className="mb-[3px]">
                  <InfoIcon aria-label="goal trigger" className="h-3 w-3 fill-current text-dodger-blue-300" />
                </div>
              </Tooltip>
            </div>
          );
        },
        accessorKey: 'conversion',
        size: 120,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell: function ConversionCell({ row }) {
          if (isDraft) return '-';
          return AbTestTableTextWinner(row.original.results.hasBestImprovement, row.original.results.conversions || 0);
        },
      },
      {
        header: 'Conversion Rate',
        accessorKey: 'conversionRate',
        size: 150,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell: function ConversionRateCell({ row }) {
          if (isDraft) return '-';
          const text = `${parseDecimal(row.original.results.conversionRate || 0, 2)}%`;
          return AbTestTableTextWinner(row.original.results.hasBestImprovement, text);
        },
      },
      {
        header: 'Improvement',
        accessorKey: 'improvement',
        size: 80,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell: function ImprovementCell({ row }) {
          return AbTestTableTextWinner(
            row.original.results.hasBestImprovement,
            row.original.results.improvementFromControl
              ? `${parseDecimal(row.original.results.improvementFromControl, 2)}%`
              : null,
            row.original.results.improvementFromControl,
          );
        },
      },
    ],
    [abTest, isDraft],
  );

  return (
    <div className="mb-10">
      <ReactTable
        columns={columns}
        data={variants}
        ref={{ tableRef }}
        enableSorting={false}
        rowPadding={true}
        rowHeight={50}
        fetching={fetching}
        useVirtualization={true}
        rowCustomClassname={(row) =>
          classNames({
            'bg-alice-blue-500 hover:bg-alice-blue-500': row.original.isWinner,
          })
        }
      />
    </div>
  );
}

function variantResultSortFunc(variant1, variant2) {
  return (variant2.results.improvementFromControl || 0) - (variant1.results.improvementFromControl || 0);
}

function variantPositionSortFunc(variant1, variant2) {
  const firstVariant = variant1.position || 0;
  const secondVariant = variant2.position || 0;

  if (firstVariant > secondVariant) return 1;
  if (firstVariant < secondVariant) return -1;
  return 0;
}

// eslint-disable-next-line no-restricted-syntax
export default function AbTestResults() {
  const modal = useModal();
  const perfMetricsStarted = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const notifications = useNotifications();
  const { isSharing, sharedResource } = useAuthContext();

  const { sites } = useSite({
    sitesQuery: isSharing ? SINGLE_SITE_QUERY : SITES_QUERY,
    dontSelectSite: true,
    sitesQueryProps: isSharing ? { variables: { id: sharedResource.resource.siteId } } : undefined,
  });
  const permissions = usePermissions();

  const {
    data: resultData,
    isFetching,
    isInitialLoading,
    error,
  } = useQuery({
    ...abTestDetailQuery({
      id: Number(id),
    }),
    enabled: !!id,
  });

  useEffect(() => {
    if (window.CE2?.timing && isProd && !perfMetricsStarted.current) {
      window.CE2.timing.start('ab_test_detail_v2');
      perfMetricsStarted.current = true;
    }
  }, []);

  useEffect(() => {
    if (window.CE2?.timing && isProd && perfMetricsStarted.current && (resultData || error) && !isFetching) {
      window.CE2.timing.stop('ab_test_detail_v2');
      perfMetricsStarted.current = false;
    }
  }, [error, isFetching, resultData]);

  useEffect(() => {
    if (error && error?.graphQLErrors?.find((error) => error.message === 'Not found')) {
      notifications.error({
        title: 'A/B Test Not Found',
        content: `Couldn't find the A/B Test you were looking for`,
        timeout: 3000,
        skipHoneybadger: true,
      });
      navigate(
        {
          pathname: '/ab-tests',
          search: location.search,
        },
        { replace: true },
      );
    }
  }, [error, navigate, location.search, notifications]);

  const data = resultData?.abTestDetail ?? null;

  const site = useMemo(() => {
    if (!sites || !sites.length || !data?.siteId) return null;
    return sites.find((site) => site.id === data.siteId);
  }, [sites, data]);

  const { active: activeVariants, retired: retiredVariants } = useMemo(() => {
    const active = [];
    const retired = [];

    if (!data?.variants) {
      return { active, retired };
    }

    for (let variant of data.variants) {
      if (variant.isRetired) {
        retired.push(variant);
      } else {
        active.push(variant);
      }
    }

    return { active, retired };
  }, [data?.variants]);

  const resultSortedActiveVariants = [...activeVariants.sort(variantResultSortFunc)];
  const positionSortedActiveVariants = [...activeVariants.sort(variantPositionSortFunc)];
  const positionSortedRetiredVariants = [...retiredVariants.sort(variantPositionSortFunc)];

  const backTo = new URLSearchParams(window.location.search).get('back');
  const isBackToGoal = backTo && backTo.startsWith('/goals/');
  const canGoBackToDash = isBackToGoal
    ? permissions.can('navigate', FEATURES.GOALS).allowed
    : permissions.can('navigate', FEATURES.AB_TESTING).allowed;

  const addNewVariant = useCallback(
    (editorPatch) => {
      if (data.type === abTestTypes.SPLIT) {
        modal.show(<AddSplitVariant abTest={data} />);
      } else {
        navigate(
          {
            pathname: `/ab-tests/${id}/variants/new`,
          },
          {
            replace: true,
            state: { editorPatch },
          },
        );
      }
    },
    [data, navigate, id, modal],
  );

  const editVariant = useCallback(
    (abTest, variant) => {
      if (abTest.type === abTestTypes.SPLIT) {
        modal.show(<EditSplitVariant abTest={abTest} variant={variant} />);
      } else {
        navigate(`/ab-tests/${abTest.id}/variants/${variant.id}/edit`);
      }
    },
    [modal, navigate],
  );

  const isDraft = data?.status === AB_TEST_STATUSES.DRAFT;

  if (isDraft) {
    return <Navigate to={`/ab-tests/${id}/edit`} replace={true} />;
  }

  if (error && isSharedResourceSubscriptionError(error)) {
    return <Navigate replace to="/not-found" />;
  }

  return (
    <DashboardPage>
      <DashHeader
        titleComponent={
          <Breadcrumb>
            {!isSharing && (
              <BreadcrumbItem active={false} to="/ab-tests">
                A/B Testing
              </BreadcrumbItem>
            )}
            <BreadcrumbItem active={true}>
              <div className="mr-5 flex items-center space-x-1">
                {data?.name ? (
                  <>
                    <span className="truncate">{data.name}</span>
                    {data.permissions?.canRename ? (
                      <Tooltip placement="bottom" tooltipContent="Rename A/B Test">
                        <IconButton
                          icon={<EditIcon className="h-4 w-4 fill-current" />}
                          className="h-full shrink-0 text-malibu-500 hover:text-dodger-blue-500"
                          onClick={() => {
                            modal.show(<AbTestRenameModal abTest={data} />);
                          }}
                          label="edit AB test name"
                        />
                      </Tooltip>
                    ) : null}
                  </>
                ) : isInitialLoading ? (
                  <SkeletonLine width="300px" height="16px" />
                ) : null}
              </div>
            </BreadcrumbItem>
          </Breadcrumb>
        }
        actionButton={
          <div className="flex flex-shrink-0 items-center space-x-2">
            {canGoBackToDash ? (
              <LinkWithParams className="text-link flex items-center" to={backTo || '/ab-tests'} excluded={['back']}>
                <ArrowIcon className="mr-2.5 h-3 w-3 shrink-0 rotate-180 fill-current" /> Back to{' '}
                {isBackToGoal ? 'goal' : 'all A/B Tests'}
              </LinkWithParams>
            ) : null}
            <AbTestActionButton entity={data} redirectOnDelete="/ab-tests" />
          </div>
        }
      />
      <SitePickerBar
        sites={site ? [site] : []}
        selectedSite={site}
        loading={!site}
        pageDisplayName="A/B Test Results"
      />

      {!isDraft && data?.hasResults === false ? (
        <DashboardBanner title="Your results aren't quite ready" type="info">
          <div className="text-gray-700 text-sm">
            Check back in a few hours once your site has some visitor traffic.
          </div>
        </DashboardBanner>
      ) : null}
      <div className="p-10">
        <SEO title="A/B Testing Result" />
        {isFetching && !data ? (
          <div className="mt-16 flex w-full items-center justify-center">
            <Spinner />
            <div className="ml-2.5">Loading...</div>
          </div>
        ) : !data ? (
          <div className="mt-16 flex w-full items-center justify-center">
            <span>Failed to load data</span>
          </div>
        ) : (
          <>
            <AbTestTopCards data={data} />
            <AbTestTable abTest={data} variants={resultSortedActiveVariants} fetching={false} />
            <AbTestVariantsPanel
              id={data.id}
              site={site}
              status={data.status}
              abTest={data}
              variants={positionSortedActiveVariants}
              heading="Test Variants"
              canAddVariants={data.permissions.canAddVariants}
              onAddVariant={addNewVariant}
              onEditVariant={editVariant}
            />
            {retiredVariants.length > 0 ? (
              <AbTestVariantsPanel
                id={data.id}
                site={site}
                status={data.status}
                abTest={data}
                variants={positionSortedRetiredVariants}
                heading="Retired Variants"
                onAddVariant={(editorPatch) => {
                  navigate(
                    {
                      pathname: `/ab-tests/${data.id}/variants/new`,
                      state: { editorPatch },
                    },
                    { replace: true },
                  );
                }}
                canEditVariants={false}
              />
            ) : null}
          </>
        )}
      </div>
    </DashboardPage>
  );
}
