import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const goalsFeatureKeys = createQueryKeys('goalsFeature', {
  goalList: ({ siteId, search, limit, order, sort }) => [{ siteId, search, limit, order: { field: order, sort } }],
  goalListMinimal: ({ siteId, search, limit }) => [{ siteId, search, limit }],
  goalDetail: ({ id, presetDate, startDate, endDate }) => [{ id, presetDate, startDate, endDate }],
  goalDistribution: ({ id, presetDate, startDate, endDate }) => [{ id, presetDate, startDate, endDate }],
  goalCounts: ({ id, presetDate, startDate, endDate }) => [{ id, presetDate, startDate, endDate }],
  goalSessionsCount: ({ id, presetDate, startDate, endDate }) => [{ id, presetDate, startDate, endDate }],
});

export const goalListQuery = ({ siteId, search, limit, order, sort }) => ({
  ...goalsFeatureKeys.goalList({ siteId, search, limit, order, sort }),
  getNextPageParam: (lastPage) => (lastPage.goalList.hasNextPage ? lastPage.goalList.nextPageCursor : undefined),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query GoalList($siteId: Int, $cursor: String, $search: String, $limit: Int, $order: GoalListOrder) {
        goalList(site: $siteId, cursor: $cursor, search: $search, limit: $limit, order: $order) {
          list {
            __typename
            id
            name
            abTestsCount
            abTestsCountRunning
            abTestsCountStopped
            abTestsCountActive
            abTestsCountTotal
            conversionsCount
            createdAt
            displayData
            icon
            permissions {
              canView
              canEdit
              canDelete
              canShare
            }
            uiMode
          }
          hasNextPage
          nextPageCursor
        }
      }
    `,
  },
});

export const goalListMinimalQuery = ({ siteId, search, limit }) => ({
  ...goalsFeatureKeys.goalListMinimal({ siteId, search, limit }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query GoalList($siteId: Int!, $search: String, $limit: Int) {
        goalList(site: $siteId, search: $search, limit: $limit) {
          list {
            id
            name
          }
        }
      }
    `,
  },
});

export const GOAL_NAME_ONLY = gql`
  query GoalDetail($id: Int!) {
    goalDetail(id: $id) {
      id
      name
    }
  }
`;

export const goalDetailQuery = ({ id, startDate, endDate, presetDate }) => ({
  ...goalsFeatureKeys.goalDetail({ id, startDate, endDate, presetDate }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query GoalDetail($id: Int!, $startDate: Int, $endDate: Int, $presetDate: String) {
        goalDetail(id: $id, startDate: $startDate, endDate: $endDate, presetDate: $presetDate) {
          __typename
          id
          name
          abTestsCount
          abTestsCountRunning
          abTestsCountStopped
          abTestsCountActive
          abTestsCountTotal
          autoName
          createdAt
          icon
          deletedAt
          displayData
          conversionsCount
          permissions {
            canView
            canEdit
            canDelete
            canShare
          }
          purpose
          triggers {
            autoName
            createdAt
            filter
            id
            name
            uiMode
            valueType
            fixedValue {
              worth
              currency
            }
            elementValue {
              currency
              condition {
                trackingOption
                elementSelectorType
                pageUrl
                wildcardUrl
                onPage
                onAnyPage
                selectorForDesktop
                selectorForTablet
                selectorForPhone
              }
            }
          }
          siteId
          syncValues
          hasValue
          totalValue {
            displayWorth
            displayCurrency
          }
          abTests(startDate: $startDate, endDate: $endDate, presetDate: $presetDate) {
            id
            duration
            publishedAt
            stoppedAt
            createdAt
            createdByUserName
            name
            pageUrl
            status
            type
            totalConversions
            totalVisitors
            variants {
              id
              redirectUrl
              variantName
              results {
                conversions
              }
            }
            variantsCount
          }
        }
      }
    `,
  },
});

export const goalDistributionQuery = ({ id, presetDate, startDate, endDate }) => ({
  ...goalsFeatureKeys.goalDistribution({ id, presetDate, startDate, endDate }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query GoalDistribution($id: Int!, $startDate: Int, $endDate: Int, $presetDate: String) {
        goalDistribution(id: $id, startDate: $startDate, endDate: $endDate, presetDate: $presetDate) {
          conversionsCount
          uniqueConversionsCount
          date
          sessionsCount
          totalValue
        }
      }
    `,
  },
});

export const goalCountsQuery = ({ id, presetDate, startDate, endDate }) => ({
  ...goalsFeatureKeys.goalCounts({ id, presetDate, startDate, endDate }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query GoalCounts($id: Int!, $presetDate: String, $startDate: Int, $endDate: Int) {
        goalCounts(id: $id, presetDate: $presetDate, startDate: $startDate, endDate: $endDate) {
          conversionsCount
          sessionsCount
          uniqueConversionsCount
          uniqueSessionsCount
        }
      }
    `,
  },
});

export const goalSessionsCountQuery = ({ id, presetDate, startDate, endDate }) => ({
  ...goalsFeatureKeys.goalSessionsCount({ id, presetDate, startDate, endDate }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query GoalSessionsCount($id: Int!, $startDate: Int, $endDate: Int, $presetDate: String) {
        goalSessionsCount(id: $id, startDate: $startDate, endDate: $endDate, presetDate: $presetDate)
      }
    `,
  },
});
