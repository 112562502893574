import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { SkeletonLine } from '@crazyegginc/hatch';

import { Section } from '../Section';
import { TrendMetric } from '../TrendMetric';
import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';
import { usePermissions, useSelectedSite } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';
import { goalListMinimalQuery } from '/src/features/goals/queries';
import { NoGoalsWall } from '/src/features/goals/paywalls/NoGoalsWall';

import { ReactComponent as GoalsIcon } from '@crazyegginc/hatch/dist/images/icon-goal-filled.svg';

export function GoalsSection({ data, loading }) {
  return (
    <Section className="col-span-1">
      <Section.Header icon={GoalsIcon} color="bg-lima-500" to="/goals">
        <span>Goals</span>
      </Section.Header>
      <Section.Body>
        <Content data={data} loading={loading} />
      </Section.Body>
    </Section>
  );
}

function Content({ data, loading }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.GOALS);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.GOALS} reason={reason} mini={true} />;
  }

  return <GoalContent data={data} loading={loading} />;
}

function GoalContent({ data, loading }) {
  const { selectedSite } = useSelectedSite();

  const { data: goalsData, isLoading } = useQuery({
    ...goalListMinimalQuery({ siteId: selectedSite?.id, limit: 1 }),
    enabled: Boolean(selectedSite?.id),
  });

  const goals = goalsData?.goalList?.list;

  if (!isLoading && goals.length === 0) {
    return <NoGoalsWall mini={true} />;
  }

  return (
    <>
      <div className="mb-5 flex w-full items-stretch justify-between gap-x-3">
        <TrendMetric
          text="Active"
          value={data?.goals.active.current}
          oldValue={data?.goals.active.previous}
          below={true}
          loading={loading}
        />
        <TrendMetric
          text="Had conversions"
          value={data?.goals.withConversions.current}
          oldValue={data?.goals.withConversions.previous}
          below={true}
          loading={loading}
        />
        <TrendMetric
          text="Total conversions"
          value={data?.goals.totalConversions.current}
          oldValue={data?.goals.totalConversions.previous}
          below={true}
          loading={loading}
        />
      </div>
      <div className="rounded bg-white-lilac-500 px-4 py-2">
        <div className="text-body-1 mb-2">Most converted</div>
        {loading ? (
          <>
            <div className="mb-1 flex h-5 items-center">
              <SkeletonLine className="!w-4/5" />
            </div>
            <div className="mb-1 flex h-5 items-center">
              <SkeletonLine className="!w-3/5" />
            </div>
            <div className="mb-0 flex h-5 items-center">
              <SkeletonLine className="!w-2/3" />
            </div>
          </>
        ) : (
          <>
            {data?.goals.topConversions.map((g) => (
              <div key={g.id} className="mb-1 flex last:mb-0">
                <div key={g.id} className="grow truncate">
                  <Link className="text-link" to={`/goals/${g.id}`}>
                    {g.name}
                  </Link>
                </div>
                <div className="ml-4 flex w-12 shrink-0 items-center justify-end text-right">
                  {g.conversions.toLocaleString()}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <Section.SeeMore to="/goals" />
    </>
  );
}
